import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8beb5e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  "data-testid": "app-table-filters-title"
}
const _hoisted_2 = { class: "text--ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_AppSearch = _resolveComponent("AppSearch")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_AppNoData = _resolveComponent("AppNoData")!
  const _component_AppLoadingRow = _resolveComponent("AppLoadingRow")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, _mergeProps(_ctx.$props, {
    ref: "table",
    pagination: _ctx.paginationModel,
    "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paginationModel) = $event)),
    class: ["app-table__content", {
      'app-table__content--no-data': !_ctx.rows.length || _ctx.counter == 0,
      'app-table__content--checkbox': _ctx.selection === 'multiple',
    }],
    "data-testid": "app-table-content",
    filter: _ctx.filterTable,
    "hide-header": _ctx.hideHeaders,
    "wrap-cells": _ctx.wrapCells,
    loading: _ctx.loading,
    "rows-per-page-options": _ctx.rowsPerPageOptions,
    "hide-pagination": !_ctx.showPagination,
    flat: "",
    onRequest: _ctx.onRequest
  }), _createSlots({
    "top-left": _withCtx(() => [
      (_ctx.$slots.Filters || _ctx.$slots.Title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "Filters", {}, undefined, true),
            _renderSlot(_ctx.$slots, "Title", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "header-left", {}, undefined, true),
      (_ctx.search)
        ? (_openBlock(), _createBlock(_component_AppSearch, {
            key: 1,
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
            "data-testid": "table-search"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    "body-selection": _withCtx((props) => [
      _createVNode(_component_q_td, {
        class: _normalizeClass([{ 'selected-row': props.row?.highlighted || false }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppCheckbox, {
            modelValue: props.selected,
            "onUpdate:modelValue": ($event: any) => ((props.selected) = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 2
      }, 1032, ["class"])
    ]),
    "body-cell": _withCtx((props) => [
      _createVNode(_component_q_td, {
        props: props,
        class: _normalizeClass([{ 'selected-row': props.row.highlighted }])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(props.value), 1)
        ]),
        _: 2
      }, 1032, ["props", "class"])
    ]),
    "no-data": _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_AppNoData, {
            key: 0,
            class: "app-table__no-data",
            message: _ctx.noDataMessageKey
          }, null, 8, ["message"]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, slot, index) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          (_ctx.isBodyCellElement(slot))
            ? (_openBlock(), _createBlock(_component_q_td, {
                key: 0,
                props: scope,
                class: _normalizeClass([{ 'selected-row': scope.row?.highlighted || false }])
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)), undefined, true)
                ]),
                _: 2
              }, 1032, ["props", "class"]))
            : _renderSlot(_ctx.$slots, slot, _normalizeProps(_mergeProps({ key: 1 }, scope)), undefined, true)
        ])
      }
    }),
    (_ctx.showCounter && _ctx.counter > 4)
      ? {
          name: "top-right",
          fn: _withCtx(() => [
            _createVNode(_component_AppButton, {
              class: "store-table__see-all-tag",
              "data-testid": "store-table-see-all-tag",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('counterClick')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('store.seeAll', [_ctx.counter])) + " ", 1),
                _createVNode(_component_AppIcon, {
                  name: _ctx.roundArrowForward,
                  size: "20px"
                }, null, 8, ["name"])
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined,
    (!_ctx.dataLoaded && !_ctx.rows.length)
      ? {
          name: "loading",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfLoadingRows, (element) => {
              return (_openBlock(), _createBlock(_component_AppLoadingRow, {
                key: element,
                "number-of-skeletons": _ctx.skeletons.length,
                skeletons: _ctx.skeletons
              }, null, 8, ["number-of-skeletons", "skeletons"]))
            }), 128))
          ]),
          key: "1"
        }
      : undefined
  ]), 1040, ["pagination", "class", "filter", "hide-header", "wrap-cells", "loading", "rows-per-page-options", "hide-pagination", "onRequest"]))
}