<template>
  <q-toggle v-bind="$props" v-model="model" class="app-toggle" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AppToggle',

  props: {
    modelValue: {
      type: [Object, Array, String, Number, Boolean] as PropType<unknown>,
      required: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(newValue: unknown) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
});
</script>

<style scoped lang="scss">
.app-toggle {
  gap: 8px;
}

.app-toggle :deep(.q-toggle__inner--truthy) {
  color: $primary-color;
}

.app-toggle :deep(.q-toggle__label) {
  padding-left: unset;
}
</style>
