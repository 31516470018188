import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9a97056"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-header",
  "data-testid": "app-header"
}
const _hoisted_2 = { class: "app-header__leading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _renderSlot(_ctx.$slots, "trailing", {}, undefined, true)
  ]))
}