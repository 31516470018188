<template>
  <AppButton
    dense
    :ripple="false"
    :class="['clear-icon', { 'clear-icon--error': hasErrors }]"
    data-testid="app-clear-icon"
  >
    <IconClear class="icon-clear" />
  </AppButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import IconClear from '@/assets/icons/icon-clear.vue';

export default defineComponent({
  name: 'AppClearIcon',

  components: {
    AppButton,
    IconClear,
  },

  props: {
    hasErrors: { type: Boolean, default: false },
  },
});
</script>

<style scoped lang="scss">
.clear-icon {
  width: 24px;
  padding: 0;

  &--error {
    color: $red-500;
    .icon-clear :deep(path) {
      fill: $red-500;
    }
  }
}

:deep(.q-icon) {
  font-size: 14px;
}
</style>
