import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createBlock(_component_AppButton, {
    dense: "",
    type: "icon",
    ripple: false,
    class: _normalizeClass(['password-icon', { 'password-icon--error': _ctx.passwordHasErrors }])
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showPasswordIcon)))
    ]),
    _: 1
  }, 8, ["class"]))
}