import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconClear = _resolveComponent("IconClear")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createBlock(_component_AppButton, {
    dense: "",
    ripple: false,
    class: _normalizeClass(['clear-icon', { 'clear-icon--error': _ctx.hasErrors }]),
    "data-testid": "app-clear-icon"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IconClear, { class: "icon-clear" })
    ]),
    _: 1
  }, 8, ["class"]))
}