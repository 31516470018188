<script setup lang="ts">
import { computed } from 'vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import {
  matFullscreen,
  matFullscreenExit,
} from '@quasar/extras/material-icons';
import AppButton from '@/components/app/AppButton/AppButton.vue';

interface Props {
  active: boolean;
}

const props = defineProps<Props>();

const icon = computed<string>(() => {
  return props.active ? matFullscreenExit : matFullscreen;
});
</script>

<template>
  <AppButton
    v-bind="$attrs"
    :class="[
      'app-button-expand',
      {
        'app-button-expand--active': active,
      },
    ]"
    type="icon"
    rounded
  >
    <AppIcon :name="icon" />
  </AppButton>
</template>

<style scoped lang="scss">
.app-button-expand {
  color: $gray-600;
  background-color: $white;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.app-button-expand--active {
  background-color: $primary-background;
}
</style>
