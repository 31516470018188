import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, _mergeProps(_ctx.$attrs, {
    class: [
      'app-button',
      `app-button--${_ctx.type}`,
      `app-button--${_ctx.size}`,
      { 'app-button--fit-content': _ctx.fitContent },
      { 'app-button--reversed': _ctx.isReversed },
    ],
    "data-testid": "app-button",
    ripple: false,
    type: _ctx.nativeType,
    unelevated: "",
    "no-caps": ""
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "prepend", {}, undefined, true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.$props.tooltipText)
        ? (_openBlock(), _createBlock(_component_BaseTooltip, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.tooltipText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class", "type"]))
}