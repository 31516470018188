<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  isCoompetitionOrRace: boolean;
  position: number;
  isChangePositionColor?: boolean;
  isTie?: boolean;
}

const props = defineProps<Props>();
const { t } = useI18n();

const classPosition = computed<string>(() => {
  if (props.isTie) {
    return 'app-position__normal-position';
  }

  return props.isCoompetitionOrRace && props.position <= 3
    ? 'app-position__top-three-position'
    : 'app-position__normal-position';
});

const userPosition = computed<string>(() => {
  if (!props.position) {
    return '-';
  }
  return props.isTie ? t('leaderboard.tie') : `${props.position}`;
});

const classTopThreePositions = computed<string>(() => {
  if (props.position > 3 || !props.isCoompetitionOrRace || props.isTie) {
    return '';
  }
  return classPosition.value === 'app-position__top-three-position' &&
    props.position === 1
    ? 'app-position__top-three-position--first'
    : 'app-position__top-three-position--second-third';
});
</script>

<template>
  <div
    :class="[
      'app-position__position',
      { 'app-position__position--fixed': !isTie },
    ]"
  >
    <div
      v-if="!props.isCoompetitionOrRace || isTie"
      class="app-position__position"
    >
      <span
        :class="[
          'app-position__normal-position',
          {
            'app-position__position--finished': isChangePositionColor,
          },
        ]"
      >
        {{ userPosition }}
      </span>
    </div>
    <div v-else :class="[{ 'app-position__gradient': position <= 3 }]">
      <div class="app-position__border">
        <span :class="[classPosition, classTopThreePositions]">
          {{ userPosition }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-position__position {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.app-position__position--fixed {
  width: 34px;
}

.app-position__top-three-position--first {
  color: $white;
  font-weight: 700;
  background: $vaibe-gradient-dark-secondary;
}

.app-position__normal-position {
  display: flex;
  background: $white;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px $gray-100;
  height: 26px;
  font-weight: 700;
  color: $gray-800;
}

.app-position__position--finished {
  background: $blue-50;
}

.app-position__top-three-position {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.app-position__gradient {
  background: $vaibe-gradient-dark-secondary;
  padding: 1px;
  border-radius: 50%;
}

.app-position__border {
  border-radius: 50%;
  background: linear-gradient($blue-25, $blue-25);
}

.app-position__top-three-position--second-third {
  font-weight: bold;
  background: $vaibe-gradient-dark-secondary;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
