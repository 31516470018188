import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee2dc428"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-currency-converter" }
const _hoisted_2 = {
  class: "app-currency-converter__input__append",
  "data-testid": "app-currency-converter-input-append"
}
const _hoisted_3 = {
  class: "app-currency-converter__input__append",
  "data-testid": "app-currency-converter-input-append-converted-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconVaibeCoin = _resolveComponent("IconVaibeCoin")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_IconExchangeArrows = _resolveComponent("IconExchangeArrows")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppInput, {
      modelValue: _ctx.baseValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.baseValue) = $event)),
      class: "app-currency-converter__input",
      "data-testid": "app-currency-converter-input",
      type: "number",
      placeholder: "0",
      error: _ctx.error,
      "format-numbers": _ctx.coinsRelated
    }, {
      append: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getCurrencyCode(_ctx.selectedCurrency.from)), 1),
        (_ctx.selectedCurrency.from === _ctx.Currency.COIN)
          ? (_openBlock(), _createBlock(_component_IconVaibeCoin, {
              key: 0,
              class: "app-currency-converter__coin"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "error", "format-numbers"]),
    _createVNode(_component_AppButton, {
      class: "app-currency-converter__exchange-button",
      "data-testid": "app-currency-converter-exchange-button",
      type: "icon",
      ripple: false,
      onClick: _ctx.swapCurrencies
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IconExchangeArrows, {
          height: "18px",
          width: "18px"
        })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_AppInput, {
      modelValue: _ctx.convertedValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.convertedValue) = $event)),
      class: "app-currency-converter__input",
      "data-testid": "app-currency-converter-input-converted-value",
      placeholder: "0",
      disable: "",
      debounce: _ctx.debounce
    }, {
      append: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getCurrencyCode(_ctx.selectedCurrency.to)), 1),
        (_ctx.selectedCurrency.to === _ctx.Currency.COIN)
          ? (_openBlock(), _createBlock(_component_IconVaibeCoin, {
              key: 0,
              class: "app-currency-converter__coin"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "debounce"])
  ]))
}