import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createBlock(_component_AppModal, {
    modelValue: _ctx.sharedStore.sharedConfirmationModal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sharedStore.sharedConfirmationModal) = $event)),
    "no-route-dismiss": "",
    persistent: ""
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.confirmationModalTitle), 1)
    ]),
    body: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.confirmationModalDescription), 1)
    ]),
    footer: _withCtx(({ dismiss }) => [
      _createVNode(_component_AppButton, {
        ref: "cancelButton",
        type: "empty",
        "data-testid": "cancel-modal",
        label: _ctx.confirmationModalCancelLabel,
        onClick: dismiss
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_AppButton, {
        ref: "discardButton",
        type: "danger",
        "data-testid": "dont-save-modal",
        label: _ctx.confirmationModalDiscardLabel,
        onClick: ($event: any) => (_ctx.onDiscardClick(dismiss))
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}