import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_radio = _resolveComponent("q-radio")!

  return (_openBlock(), _createBlock(_component_q_radio, _mergeProps({
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    class: { 'app-radio': _ctx.tabs }
  }, _ctx.$props), null, 16, ["modelValue", "class"]))
}