<template>
  <div v-if="!editable" class="app-question__container">
    <span class="app-question__order">{{ numberQuestion }}</span>
    <div class="app-question__default-custom">
      <span class="app-question__text">{{ question }}</span>
    </div>
  </div>

  <div v-else class="app-question__container app-question__container--editable">
    <span class="app-question__order">{{ numberQuestion }}</span>
    <AppInput
      v-model="customQuestion"
      :width="widthInputQuestion"
      :error="error"
      :maxlength="90"
      type="textarea"
      :rows="1"
      :autogrow="true"
    />
    <IconDelete
      v-if="showDeleteButton"
      class="app-question__remove-icon"
      width="16px"
      height="16px"
      @click="deleteQuestion"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import IconDelete from '@/assets/icons/icon-delete.vue';

export default defineComponent({
  name: 'AppQuestion',
  components: { AppInput, IconDelete },
  props: {
    numberQuestion: {
      type: Number,
      default: 0,
    },
    question: {
      type: String,
      default: '',
    },
    widthInputQuestion: {
      type: String,
      default: '200px',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['delete', 'update:question'],

  computed: {
    customQuestion: {
      get(): string {
        return this.question;
      },

      set(newValue: string): void {
        this.$emit('update:question', newValue);
      },
    },
  },

  methods: {
    deleteQuestion(): void {
      this.$emit('delete');
    },
  },
});
</script>

<style scoped lang="scss">
.app-question__container {
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
}

.app-question__order {
  font-weight: 700;
  font-size: 13px;
  line-height: 19.5px;
  padding: 2px 4px 2px 4px;
  background: $gray-25;
  border-radius: 10px;
}

.app-question__default-custom {
  background: $gray-25;
  padding: 16px;
  margin-left: 15px;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  word-break: break-word;
}

.app-question__text {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.app-question__field {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.app-question__button-delete {
  margin-bottom: 20px;
}

.app-question__remove-icon {
  cursor: pointer;
}

.app-question__container--editable .app-question__order,
.app-question__container--editable .app-question__remove-icon {
  margin-top: 11px;
  height: 24px;
}

.app-question__container--editable {
  align-items: flex-start;
  gap: 16px;
}
</style>
