<script setup lang="ts">
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import AppLabel from '@/components/app/AppLabel/AppLabel.vue';

interface Props {
  isDisabled?: boolean;
  label: string;
  tooltipMessage?: string;
}

interface Emits {
  (e: 'click'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const handleClickEvent = (): void => {
  if (!props.isDisabled) {
    emit('click');
  }
};
</script>

<template>
  <div class="app-dropdown-label">
    <BaseTooltip v-if="isDisabled && tooltipMessage">
      {{ tooltipMessage }}
    </BaseTooltip>
    <AppLabel
      :class="[
        'app-button-dropdown-option',
        {
          'app-button-dropdown-option--disabled': isDisabled,
        },
      ]"
      :disabled="isDisabled"
      :label="label"
      @click="handleClickEvent"
    />
  </div>
</template>

<style lang="scss" scoped>
.app-button-dropdown-option--disabled {
  cursor: not-allowed;
}

.app-dropdown-label {
  padding: 0px;
  width: 100%;
}
</style>
