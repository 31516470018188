<template>
  <AppButton
    dense
    type="icon"
    :ripple="false"
    :class="['password-icon', { 'password-icon--error': passwordHasErrors }]"
  >
    <component :is="showPasswordIcon" />
  </AppButton>
</template>

<script lang="ts">
import { defineComponent, Component } from 'vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import IconPasswordEye from '@/assets/icons/icon-password-eye.vue';
import IconPasswordEyeClosed from '@/assets/icons/icon-password-eye-closed.vue';

export default defineComponent({
  name: 'AppPasswordIcon',

  components: {
    AppButton,
  },

  props: {
    passwordHasErrors: { type: Boolean, default: false },
    showPassword: { type: Boolean, default: false },
  },

  computed: {
    showPasswordIcon(): Component {
      return this.showPassword ? IconPasswordEyeClosed : IconPasswordEye;
    },
  },
});
</script>

<style scoped lang="scss">
.password-icon {
  width: 24px;
  > :deep(.q-focus-helper) {
    display: none;
  }

  &--error {
    color: $red-500;
  }
}
</style>
