<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import {
  AppButtonToggleOption,
  DatePeriod,
  DurationErrorModel,
} from '@/shared/types/generic';
import { useI18n } from 'vue-i18n';
import {
  ChallengeAudienceStaticReference,
  TypeChallenge,
} from '@/shared/types/challenges';
import {
  WizardDurationModel,
  WizardDurationPreset,
  ChallengeFormat,
} from '@/shared/types/wizard';
import { isEmpty } from '@/shared/helpers/validators/validators';
import useProfileStore from '@/store/profile/useProfileStore';
import useUserStore from '@/store/user/useUserStore';
import { RequestUsersTimezones } from '@/shared/types/user';
import AppResultsRelease from '../AppResultsRelease/AppResultsRelease.vue';
import AppButtonToggle from '../AppButtonToggle/AppButtonToggle.vue';
import AppSequence from '../AppSequence/AppSequence.vue';
import AppDatePicker from '../AppDatePicker/AppDatePicker.vue';
import AppTimePicker from '../AppTimePicker/AppTimePicker.vue';
import AppLabel from '../AppLabel/AppLabel.vue';

const { t } = useI18n();
const profileStore = useProfileStore();
const userStore = useUserStore();

interface Props {
  errors: DurationErrorModel;
  showText?: boolean;
  challengeAudience: ChallengeAudienceStaticReference;
  challengeParticipants: (string | undefined)[];
  challengeFormat: ChallengeFormat;
}

interface State {
  resultsForecast: number | undefined;
}

const state = reactive<State>({
  resultsForecast: undefined,
});

const props = withDefaults(defineProps<Props>(), {
  showText: true,
});

const modelError = defineModel<DurationErrorModel>('modelError', {
  required: true,
});

const model = defineModel<WizardDurationModel>('model', {
  required: true,
});

const presetOptions = Object.values(WizardDurationPreset).map((preset) => ({
  value: preset,
  label: t(`enums.duration.${preset}`),
})) as AppButtonToggleOption[];

const isEndDateDisabled = computed<boolean>(() => {
  return model.value.preset !== WizardDurationPreset.CUSTOM;
});

function applyPreset(daysAfterStart: DatePeriod): void {
  const endDateStamp = daysAfterStart;

  model.value = {
    ...model.value,
    endDate: new Date(model.value.startDate.getTime() + endDateStamp),
  };
}

function setStartDate(): void {
  model.value = {
    ...model.value,
    startDate: new Date(
      Date.now() +
        (profileStore.userProfile?.userConfig.enrollmentTime || 0) +
        DatePeriod.MINUTE * 5,
    ),
  };
}

function updateDates(resetStartDate: boolean): void {
  switch (model.value.preset) {
    case WizardDurationPreset.DAY:
      if (resetStartDate) setStartDate();
      applyPreset(DatePeriod.DAY);
      break;
    case WizardDurationPreset.WEEK:
      if (resetStartDate) setStartDate();
      applyPreset(DatePeriod.WEEK);
      break;
    case WizardDurationPreset.MONTH:
      if (resetStartDate) setStartDate();
      applyPreset(DatePeriod.MONTH);
      break;
    default:
      break;
  }
}

async function getResultsForecast() {
  const typeChallenge =
    props.challengeAudience === ChallengeAudienceStaticReference.INDIVIDUAL
      ? TypeChallenge.INDIVIDUAL
      : TypeChallenge.TEAM;

  const request: RequestUsersTimezones = {
    audience: typeChallenge,
    startDate: model.value.startDate.getTime(),
    endDate: model.value.endDate.getTime(),
  };

  const participantsProperty =
    props.challengeAudience === ChallengeAudienceStaticReference.INDIVIDUAL
      ? 'workerReferences'
      : 'teamReferences';

  request[participantsProperty] = props.challengeParticipants as string[];

  const date = await userStore.getResultForecastUsers(request);

  state.resultsForecast = date ?? 0;
}

const endTooltip = computed<string>(() => {
  if (
    props.challengeFormat === ChallengeFormat.RACE ||
    props.challengeFormat === ChallengeFormat.TEAM_RACE
  ) {
    return t('games.challengeWizard.steps.timeline.tooltip');
  }
  return '';
});

async function checkForMultipleTimezones(
  participants: string[],
): Promise<void> {
  let request: RequestUsersTimezones = {};
  if (props.challengeAudience === ChallengeAudienceStaticReference.INDIVIDUAL) {
    request = {
      workerReferences: participants,
      audience: TypeChallenge.INDIVIDUAL,
    };
  } else {
    request = {
      teamReferences: participants,
      audience: TypeChallenge.TEAM,
    };
  }

  await userStore.getTimezoneUsersParticipants(request);
}

watch(
  () => model.value.preset,
  (newPreset: WizardDurationPreset, oldPreset: WizardDurationPreset) => {
    if (newPreset !== oldPreset && oldPreset) {
      updateDates(true);
    }
  },
);

watch(
  () => model.value.startDate,
  (newStartDate: Date, oldStartDate: Date) => {
    if (newStartDate !== oldStartDate && oldStartDate) {
      updateDates(false);
    }
  },
);

watch(
  () => model.value.endDate,
  (newEndDate: Date, oldEndDate: Date) => {
    const difference = Math.abs(
      (oldEndDate?.getTime() || 0) - (newEndDate?.getTime() || 0),
    );
    if (
      (difference > 100000 || !!oldEndDate) &&
      userStore.hasUsersDifferentTimezone
    ) {
      getResultsForecast();
    }
  },
);

watch(
  () => props.challengeParticipants,
  async (newValue) => {
    if (!isEmpty(newValue)) {
      if (
        (props.challengeFormat === ChallengeFormat.TEAM_COMPETITION ||
          props.challengeFormat === ChallengeFormat.TEAM_RACE) &&
        props.challengeParticipants.length === 2 &&
        !(
          typeof props.challengeParticipants[0] === 'string' &&
          typeof props.challengeParticipants[1] === 'string'
        )
      ) {
        userStore.hasUsersDifferentTimezone = false;
      } else {
        await checkForMultipleTimezones(newValue as string[]);
      }
      if (userStore.hasUsersDifferentTimezone) {
        getResultsForecast();
      }
    }
  },
  { immediate: true, deep: true },
);
</script>

<template>
  <div class="app-timeline-config">
    <div class="app-timeline-config__content">
      <span v-if="showText" class="app-timeline-config__title">
        {{ t('games.duration') }}
      </span>
      <div class="app-timeline-config__accelerators">
        <AppButtonToggle
          v-model="model.preset"
          class="app-duration__buttons"
          :options="presetOptions"
          hide-bottom-space
        />
      </div>
    </div>
    <div class="app-timeline-config__content">
      <span v-if="showText" class="app-timeline-config__title">
        {{ t('games.dateTime') }}
      </span>
      <div class="app-timeline-config__time">
        <span v-if="showText" class="app-timeline-config__time-intro">
          {{ t('games.challengeWizard.steps.timeline.timeIntro') }}
        </span>
        <AppSequence>
          <div>
            <span class="app-timeline-config__time-title">
              {{ t('games.start') }}
            </span>
            <div class="app-timeline-config__time-selectors">
              <AppDatePicker
                v-model="model.startDate"
                class="app-duration__start-time"
                :error="props.errors.startDate"
              />
              <AppTimePicker
                v-model="model.startDate"
                v-model:error="modelError.startTime"
                class="app-duration__start-time"
                align-icon="prepend"
              />
            </div>
          </div>
          <div>
            <AppLabel :label="t('games.end')">
              <template v-if="endTooltip" #information>
                {{ endTooltip }}
              </template>
            </AppLabel>
            <div class="app-timeline-config__time-selectors">
              <AppDatePicker
                v-model="model.endDate"
                class="app-duration__end-time"
                :disabled="isEndDateDisabled"
                :error="props.errors.endDate"
              />
              <AppTimePicker
                v-model="model.endDate"
                v-model:error="modelError.endTime"
                class="app-duration__end-time"
                align-icon="prepend"
              />
            </div>
          </div>
        </AppSequence>

        <AppResultsRelease
          v-if="userStore.hasUsersDifferentTimezone"
          :date="state.resultsForecast || 0"
          class="app-timeline-config__timezones"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-timeline-config {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.app-timeline-config__accelerators {
  background-color: $white;
  border-radius: 8px;
  padding: 32px 32px 28px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-timeline-config__time {
  background-color: $white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 32px;
}

.app-duration__buttons :deep(.app-button-toggle__toggle) {
  gap: 24px;
}

.app-timeline-config__accelerators :deep(.q-field) {
  padding-bottom: 4px;
  overflow: auto;
}

.app-timeline-config__accelerators :deep(.q-btn-group) {
  overflow: auto;
  padding: 10px;
}

.app-duration__buttons :deep(.app-button-toggle__toggle .q-btn) {
  background-color: $white;
  border-radius: 8px;
  transition: border 0.3s ease-in-out;
  height: 48px;
  flex: 1;
  border: 1px solid $gray-50;
  box-shadow: 0px 4px 16px 0px rgba(28, 32, 34, 0.05);
  font-weight: 700;
}

.app-duration__buttons
  :deep(.app-button-toggle .app-button-toggle__toggle .q-btn.bg-null) {
  border: 2px solid $gray-600;
  font-weight: 700;
}

.app-timeline-config__title {
  font-weight: 700;
  font-size: 16px;
  color: $gray-500;
  margin-bottom: 8px;
}

.app-timeline-config__content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.app-timeline-config__time-selectors {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 4px;
}

:deep(.app-date-picker),
:deep(.app-duration__start-time),
:deep(.app-duration__end-time) {
  max-width: 160px;
}

.app-timeline-config__time-title {
  color: $gray-700;
  font-size: 14px;
  font-weight: 700;
}

:deep(.app-label__label) {
  color: $gray-700;
  font-size: 14px;
  font-weight: 700;
}

.app-timeline-config__time-intro {
  color: $gray-700;
  font-size: 16px;
  font-weight: 400;
}

:deep(.q-btn) {
  min-width: 112px;
}

:deep(.app-error-message__text) {
  white-space: break-spaces;
}

:deep(.app-error-message) {
  width: 176px;
}

.app-timeline-config__timezones {
  margin-left: 24px;
}
</style>
